import React, {useState} from 'react';
import {Nav} from "../../components";
import '../../style/sell-in-bulk.scss'
import {Button} from "../../components/common";
import Footer from "../../components/footer";
import {collection, addDoc} from 'firebase/firestore/lite';
import {db} from "../../firebase";
import {CONTACT_COLLECTION_URL, REACH_OUTS_TYPES} from "../../data/consts";

const Contact = ({location}) => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const sendEmail = async (e) => {
        e.preventDefault();

        const col  = collection(db, CONTACT_COLLECTION_URL)
        const result = await addDoc(col, {
            name,
            email,
            message,
            date: (new Date()).getUTCDate(),
            type: REACH_OUTS_TYPES.CONTACT
        })

        if(result.id) {
            window.location.href = '/thank-you';

            setName('');
            setEmail('');
            setMessage('');
        } else {
            alert('Unfortunately we couldn\'t get your email')
        }
    }

    return <div className='siteWrapper'>
        <Nav
            location={location}
            title={'Turn your company’s old devices into cash'}
            darkNav={true}
        />

        <div className="pageContainer sell-in-bulk">
            <section className="bulk-form">
                <div className="contact-info">
                    <h1>Let’s talk</h1>

                    <div className="info-group">
                        <p className={'additional-info color-waterloo'}>
                            Let us know how we can help and we’ll get right back to you.
                        </p>
                    </div>


                    <div className="info-group">
                        <p className={'additional-info color-waterloo'}>
                            If you wish to write us an email instead please use:
                        </p>
                        <a href="mailto:support@swaptecs.com">
                            <b>support@swaptecs.com</b>
                        </a>
                    </div>

                    <div className="info-group">
                        <p className={'additional-info color-waterloo'}>
                            For business inquires:
                        </p>
                        <a href="mailto:business@swaptecs.com">
                            <b>business@swaptecs.com</b>
                        </a>
                    </div>
                </div>

                <div className="bulk-form-inputs">
                    <form onSubmit={e => sendEmail(e)}>
                        <div className="input-group">
                            <label htmlFor="name">Your name <span className={'color-red'}>*</span></label>
                            <input
                                id={'name'}
                                type="text"
                                name={'name'}
                                placeholder={'Example: John Smith'}
                                value={name}
                                onChange={e => setName(e.target.value)}
                            />
                        </div>

                        <div className="input-group">
                            <label htmlFor="email">Your email address <span className={'color-red'}>*</span></label>
                            <input
                                id={'email'}
                                type="email"
                                name={'email'}
                                placeholder={'Example: john@mail.com'}
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                            />
                        </div>

                        <div className="input-group">
                            <label htmlFor="additional-info">Message <span className={'color-red'}>*</span></label>
                            <textarea
                                name="additional-info"
                                id="additional-info"
                                placeholder={'Your message...'}
                                value={message}
                                onChange={e => setMessage(e.target.value)}
                            />
                        </div>

                        <Button
                            className={'primary filled long'}
                            label={'Submit'}
                        />
                    </form>
                </div>
            </section>
        </div>

        <Footer />
    </div>
};

export default Contact;